import React, { useState } from "react";
import {
  Box,
  Toolbar,
  Button,
  Typography,
  FormControl,
  Select,
  MenuItem,
  InputLabel,
  InputBase,
  IconButton,
  useMediaQuery,
  Chip,
} from "@mui/material";
import { Search } from "@mui/icons-material";
import Header from "../../components/Header";
import FlexBetween from "../../components/FlexBetween";
import Ricetta from "../../components/Ricetta";
import { useGetRicetteQuery } from "../../redux/state/api";
import { useNavigate } from "react-router-dom";
import { useGetTagsQuery } from "../../redux/state/api";

const Ricette = () => {
  const [categoryFilter, setCategoryFilter] = useState("");
  const [tipPastoFilter, setTipPastoFilter] = useState("");
  const [nameFilter, setNameFilter] = useState("");
  const isNonMobile = useMediaQuery("(min-width: 1000px)");
  const navigate = useNavigate();
  const categories = [
    { id: 1, name: "Colazione" },
    { id: 2, name: "Pranzo" },
    { id: 3, name: "Spuntino" },
    { id: 4, name: "Cena" },
  ];
  const { data: ricette, isLoading: isRicetteLoading } = useGetRicetteQuery();
  const { data: tags, isLoading: isTagsLoading } = useGetTagsQuery();

  if (isRicetteLoading || isTagsLoading) {
    return <div class="loader-container">
    <div class="loader"></div>
</div>;
  }

  const handleChangeSelectTipPasto = (event) => {
    setTipPastoFilter(event.target.value);
  };

  const handleChangeCategoria = (event) => {
    setCategoryFilter(event.target.value);
  };

  const handleChangeSearch = (event) => {
    setNameFilter(event.target.value);
  };

  const filteredData = ricette.filter((item) => {
    const matchesTipPasto =
      !categoryFilter ||
      item.tags.some((tag) =>
        tag.tag.toLowerCase().includes(categoryFilter.toLowerCase())
      );
    const matchesCategory =
      !tipPastoFilter ||
      item.categories.some((categoria) =>
        categoria.name.toLowerCase().includes(tipPastoFilter.toLowerCase())
      );
    const matchesName =
      !nameFilter || item.name.toLowerCase().includes(nameFilter.toLowerCase());

    return matchesCategory && matchesName && matchesTipPasto;
  });

  return (
    <Box m="1.5rem 2.5rem">
      <Toolbar sx={{ justifyContent: "space-between" }}>
        {/*LEFT SIDE*/}

        <Header title="Le tue ricette" />

        {/*RIGHT SIDE*/}
        <Button
          sx={{  background: 'linear-gradient(to top right, #B0D99C, #68BE6A)',
  textTransform: 'none',
  fontWeight: 600,
  padding:"8px 16px",
  boxShadow: 'none',
  '&:hover, &:active, &:focus': {
    boxShadow: 'none'
  }
}}
          variant="contained"
          size="medium"
          onClick={() => navigate("/ricette/creazionericetta")}
          style={{ borderRadius: "8px"}}
        >
          Aggiungi ricetta
        </Button>
      </Toolbar>
      <Box mt="48px" ml="18px">
        <Typography color="#2F1847" fontSize="24px" fontWeight="700">
          Tutte le ricette
        </Typography>
      </Box>
      <Toolbar
        sx={{
          marginLeft: "-18px",
          marginTop: "12px",
          justifyContent: "space-between",
        }}
      >
        <Box>
          <FormControl sx={{ m: 1, minWidth: 300 }}>
            <InputLabel id="tipologia-pasto-select-label" color="secondary">
              Tipologia di pasto
            </InputLabel>
            <Select
              labelId="tipologia-pasto-select-label"
              id="selectTipPasto"
              label="Tipologia di pasto"
              value={tipPastoFilter}
              onChange={handleChangeSelectTipPasto}
              color="secondary"
              renderValue={(selected) => (
                <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}> 
                  <Chip
                    key={categories.find(
                      (category) => category.name === selected
                    ).id}
                    label={categories.find(
                      (category) => category.name === selected
                    ).name}
                    style={{
                      backgroundColor: 'rgba(70, 72, 104, 1)',
                      color: "white",
                      fontWeight: "500",
                      borderRadius: "16px"
                    }} 
                  />
                </Box>
              )}
            >
              <MenuItem value={""}>Mostra Tutti</MenuItem>
              {categories.map(({ name, id }) => (
                <MenuItem key={id} value={name}>
                  {name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <FormControl sx={{ m: 1, minWidth: 300 }}>
            <InputLabel id="categoria-select-label" color="secondary">
              Categoria
            </InputLabel>
            <Select
              labelId="categoria-select-label"
              id="selectCategoria"
              label="Categoria"
              value={categoryFilter}
              onChange={handleChangeCategoria}
              color="secondary"
              renderValue={(selected) => (
                <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}> 
                  <Chip
                    key={tags.find(
                      (tag) => tag.tag === selected
                    ).id}
                    label={tags.find(
                      (tag) => tag.tag === selected
                    ).name}
                    style={{
                      backgroundColor: 'rgba(70, 72, 104, 1)',
                      color: "white",
                      fontWeight: "500",
                      borderRadius: "16px"
                    }} 
                  />
                </Box>
              )}
            >
              <MenuItem value={""}>Mostra Tutti</MenuItem>
              {tags.map(({ id, tag, name }) => (
                <MenuItem key={id} value={tag} name={name}>
                  {name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Box>
        <Box>
          <FlexBetween gap="1.5rem">
            <FlexBetween
              backgroundColor={"transparent"}
              borderRadius="9px"
              gap="3rem"
              p="0.5rem 1.5rem"
              border={"1px solid var(--border, rgba(193, 199, 205, 0.50))"}
            >
              <InputBase
                placeholder="Cerca tra le ricette..."
                onChange={handleChangeSearch}
              />
              <IconButton>
                <Search sx={{ color: "black" }} />
              </IconButton>
            </FlexBetween>
          </FlexBetween>
        </Box>
      </Toolbar>
      {ricette || !isRicetteLoading ? (
        <Box
          mt="20px"
          ml="12px"
          display="grid"
          gridTemplateColumns="repeat(4, minmax(0, 1fr))"
          justifyContent="space-between"
          rowGap="20px"
          columnGap="1.33%"
          sx={{
            "& > div": { gridColumn: isNonMobile ? undefined : "span 4" },
          }}
        >
          {filteredData.map(({ id, image, name, description, tags, nutrition_facts }) => (

            <Ricetta
              buttonClass="different-class"
              key={id}
              id={id}
              name={name}
              description={description || "Nessuna descrizione"}
              image={image}
              tags={tags}
              nutrition_facts={nutrition_facts}
            />
          ))}
        </Box>
      ) : (
        <div class="loader-container">
    <div class="loader"></div>
</div>
      )}
    </Box>
  );
};

export default Ricette;
