import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import {
  Box,
  Button,
  Stepper,
  Step,
  StepLabel,
  Typography,
  TextField,
  FormControl,
  Select,
  MenuItem,
  InputLabel,
  Popover
} from "@mui/material";
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Check from "@mui/icons-material/Check";
import { styled } from "@mui/material/styles";
import StepConnector, {
  stepConnectorClasses,
} from "@mui/material/StepConnector";
import { useNavigate, useLocation } from "react-router-dom";
import Header from "../../components/Header";
import {
  useGetVisitsQuery,
  usePostMealMutation,
  usePostMealPlansMutation,
  usePostRemindersMutation,
  usePutRemindersMutation,
  useGetMealPlanDetailsQuery,
  useLazyGetMealPlanDaysQuery,
  usePutMealPlansMutation,
  useDeleteBatchMealsMutation,
} from "../../redux/state/api";
import { MealPlanTable } from "../../components/MealPlanTable";
import axios from "axios"
import { useSelector } from "react-redux";


const QontoConnector = styled(StepConnector)(({ theme }) => ({
  [`&.${stepConnectorClasses.alternativeLabel}`]: {
    top: 10,
    left: "calc(-50% + 16px)",
    right: "calc(50% + 16px)",
  },
  [`&.${stepConnectorClasses.active}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      borderColor: "#784af4",
    },
  },
  [`&.${stepConnectorClasses.completed}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      borderColor: "#784af4",
    },
  },
  [`& .${stepConnectorClasses.line}`]: {
    borderColor:
      theme.palette.mode === "dark" ? theme.palette.grey[800] : "#eaeaf0",
    borderTopWidth: 3,
    borderRadius: 1,
  },
}));

const QontoStepIconRoot = styled("div")(({ theme, ownerState }) => ({
  color: theme.palette.mode === "dark" ? theme.palette.grey[700] : "#eaeaf0",
  display: "flex",
  height: 22,
  alignItems: "center",
  ...(ownerState.active && {
    color: "#784af4",
  }),
  "& .QontoStepIcon-completedIcon": {
    color: "#784af4",
    zIndex: 1,
    fontSize: 18,
  },
  "& .QontoStepIcon-circle": {
    width: 8,
    height: 8,
    borderRadius: "50%",
    backgroundColor: "currentColor",
  },
}));

const QontoStepIcon = (props) => {
  const { active, completed, className } = props;

  return (
    <QontoStepIconRoot ownerState={{ active }} className={className}>
      {completed ? (
        <Check className="QontoStepIcon-completedIcon" />
      ) : (
        <div className="QontoStepIcon-circle" />
      )}
    </QontoStepIconRoot>
  );
};

QontoStepIcon.propTypes = {
  /**
   * Whether this step is active.
   * @default false
   */
  active: PropTypes.bool,
  className: PropTypes.string,
  /**
   * Mark the step as completed. Is passed to child components.
   * @default false
   */
  completed: PropTypes.bool,
};

const Step1Content = ({
  mealPlanData,
  advice,
  id,
  handleChangeNumberOfDays,
  handleChangeName,
  handleChangeVisita,
  handleChangeGoal,
  handleChangeAdvices,
  isEditing
}) => {
  const [visita, setVisita] = useState(0);

  const handleChange = (event) => {
    setVisita(event.target.value);
  };

  const handleChangeSelectVisita = (event) => {
    handleChange(event);
    handleChangeVisita(event);
  };

  const { data: visitData, isLoading } = useGetVisitsQuery(id);

  if (isLoading) {
    return <div class="loader-container">
      <div class="loader"></div>
    </div>
      ;
  }
  return (
    <Box>
      <Header title={isEditing ? "Modifica piano alimentare" : "Creazione piano alimentare"} />

      <Typography
        color="#2F1847"
        fontSize="24px"
        fontWeight="700"
        variant="h1"
        sx={{ mt: "20px" }}
      >
        Informazioni del piano
      </Typography>
      <Box mt="20px" display="flex">
        <FormControl
          variant="standard"
          sx={{ m: 1, width: "100%", ml: "-5px" }}
        >
          <TextField
            value={mealPlanData.name}
            id="nome"
            label="Nome"
            variant="filled"
            color="secondary"
            required
            onChange={handleChangeName}
            error={(mealPlanData.hasOwnProperty("name") && mealPlanData.name.trim() === '')}
            InputLabelProps={{ shrink: true }}
          />
        </FormControl>
        <FormControl variant="filled" sx={{ m: 1, width: "100%" }}>
          <InputLabel
            id="visita-select-label"
            color="secondary"
          >
            Visita
          </InputLabel>
          <Select
            labelId="visita-select-element"
            id="selectVisita"
            label="Visita*"
            value={mealPlanData.visit_id ?? ""}
            onChange={handleChangeSelectVisita}
            color="secondary"
            required
          >
            {visitData.data.map(({ id, visit_date }) => (
              <MenuItem key={id} value={id}>
                {new Date(visit_date).toLocaleDateString()}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <FormControl variant="standard" sx={{ m: 1, width: "100%" }}>
          <TextField
            value={mealPlanData.number_of_days}
            id="giorni"
            label="Da quanti giorni è composto?"
            variant="filled"
            color="secondary"
            type="number"
            onChange={handleChangeNumberOfDays}
            required
            error={(mealPlanData.hasOwnProperty("number_of_days") && mealPlanData.number_of_days === 0)}
            InputLabelProps={{ shrink: true }}
          />
        </FormControl>
      </Box>
      <Box mt="20px" display="flex">
        <FormControl variant="standard" sx={{ m: 1, width: "100%", ml: "-5px" }}>
        <TextField
          value={mealPlanData.goal}
          id="obiettivo"
            label="Obiettivo"
          multiline
            rows={3}
          variant="filled"
          color="secondary"
          onChange={handleChangeGoal}
          InputLabelProps={{ shrink: true }}
        />
      </FormControl>
        <FormControl variant="standard" sx={{ m: 1, width: "100%" }}>
        <TextField
          value={advice ? advice.description : ""}
          id="consigli"
            label="Consigli"
          multiline
            rows={3}
          variant="filled"
          color="secondary"
          onChange={handleChangeAdvices}
          InputLabelProps={{ shrink: true }}
        />
      </FormControl>
    </Box>
    </Box>
  );
};

const Step2Content = ({ mealPlanData, mealsData, setMealsData, isEditing }) => {
  return (
    <Box>
      <Header title={isEditing ? "Modifica piano alimentare" : "Creazione piano alimentare"} />

      <Typography
        color="#2F1847"
        fontSize="24px"
        fontWeight="700"
        variant="h1"
        sx={{ mt: "20px" }}
      >
        Dettagli giorno
      </Typography>
      <MealPlanTable
        numberOfDays={mealPlanData.number_of_days}
        mealsData={mealsData}
        setMealsData={setMealsData}
        isEditing={isEditing}
      />
    </Box>
  );
};

const steps = ["INFORMAZIONI", "DETTAGLIO"];

const PianoAlimentare = () => {
  const pythonApiUrl = process.env.REACT_APP_PYTHON_API_URL
  const [activeStep, setActiveStep] = useState(0);
  const navigate = useNavigate();
  const location = useLocation();
  const clientId = location.state && location.state.clientId
  const mealPlanId = location.state && location.state.mealPlanId
  const accessToken = useSelector((state) => state.auth.userToken);


  const { data: mealPlanToEdit, isLoading: isMealPlanToEditLoading, isError: isMealPlanToEditError } =
    useGetMealPlanDetailsQuery(mealPlanId);

  const [getMealPlanDays] = useLazyGetMealPlanDaysQuery()
  const [mealPlanData, setMealPlanData] = useState({
    client_id: Number(clientId),
  });
  const [advice, setAdvice] = useState(null);
  const [mealsData, setMealsData] = useState([]);
  const [
    postMealPlans,
    { isLoading: isPostMealPlansLoading, isError: isPostMealPlansError },
  ] = usePostMealPlansMutation();
  const [
    postReminders,
    { isLoading: isPostRemindersLoading, isError: isPostRemindersError },
  ] = usePostRemindersMutation();
  const [
    putReminders,
    { isLoading: isPutRemindersLoading, isError: isPutRemindersError },
  ] = usePutRemindersMutation();
  const [postMeal, { isLoading: isPostMealLoading, isError: isPostMealError }] =
    usePostMealMutation();

  const [deleteBatchMeal, { isLoading: isDeleteBatchMealLoading, isError: isDeleteBatchMealError }] =
    useDeleteBatchMealsMutation();
  const [
    putMealPlans,
    { isLoading: isPutMealPlansLoading, isError: isPutMealPlansError },
  ] = usePutMealPlansMutation();
  const [anchorMissingFormField, setAnchorMissingFormField] = React.useState(null);
  const [mealIdsToDelete, setMealIdsToDelete] = React.useState([]);
  const [isGenerateRecipeLoading, setIsGenerateRecipeLoading] = useState(false);

  const computeMacroQuantity = (macroQuantity, ingredientQuantity) => {
    // since macroQuantity is already standardized base on the quantity,
    // here I recompute the macro quantity for 100g of the ingredient
    return parseFloat((macroQuantity / (ingredientQuantity / 100)).toFixed(2))
  }

  const computePercentage = (nutrient, energy, factor) => {
    return parseFloat((100 * nutrient * factor / energy).toFixed(2));
  }

  useEffect(() => {
    const getMealPlanDaysList = async () => {
      const mealPlanDaysList = await Promise.all(mealPlanToEdit.days.map(async (day) => {
        const { data: mealPlanDay } = await getMealPlanDays({ mealPlanId, dayId: day.day_number })
        return mealPlanDay
      }));
      return mealPlanDaysList;
    }
    const computeMealMacros = (mealItems) => {
      let sumOfFats = 0;
      let sumOfCarbohydrates = 0;
      let sumOfAlcohol = 0;
      let sumOfProteins = 0;
      let sumOfSugars = 0;
      let sumOfFibers = 0;
      let sumOfEnergy = 0;

      // Iterate through meal items
      mealItems.forEach((mealItem) => {
        mealItem.forEach((item) => {
          // Check if the item is selected
          if (item.selected) {
            const ingredient = item.ingredient;
            const quantity = item.quantity
            // Compute macronutrient content considering the quantity
            sumOfFats += ingredient.fats * (quantity / 100);
            sumOfCarbohydrates += ingredient.carbohydrates * (quantity / 100);
            sumOfAlcohol += ingredient.alcohol * (quantity / 100);
            sumOfProteins += ingredient.proteins * (quantity / 100);
            sumOfSugars += ingredient.sugars * (quantity / 100);
            sumOfFibers += ingredient.fibers * (quantity / 100);
            sumOfEnergy += ingredient.energy * (quantity / 100);
          }
        });
      });

      // Compute the sum of main macronutrients
      const sumOfMacronutrients = sumOfFats + sumOfCarbohydrates + sumOfAlcohol + sumOfProteins;

      // Calculate the percentage of each macronutrient
      const fatPercentage = computePercentage(sumOfFats, sumOfEnergy, 9);
      const carbohydratePercentage = computePercentage(sumOfCarbohydrates, sumOfEnergy, 4) - computePercentage(sumOfSugars, sumOfEnergy, 4);
      const alcoholPercentage = computePercentage(sumOfAlcohol, sumOfEnergy, 7);
      const proteinPercentage = computePercentage(sumOfProteins, sumOfEnergy, 4);
      const sugarPercentage = computePercentage(sumOfSugars, sumOfEnergy, 4);
      const fiberPercentage = computePercentage(sumOfFibers, sumOfEnergy, 0);

      return {
        carbohydrates: {
          quantity: parseFloat(sumOfCarbohydrates.toFixed(2)),
          percentage: parseFloat(carbohydratePercentage.toFixed(2)),
        },
        proteins: {
          quantity: parseFloat(sumOfProteins.toFixed(2)),
          percentage: parseFloat(proteinPercentage.toFixed(2)),
        },
        fats: {
          quantity: parseFloat(sumOfFats.toFixed(2)),
          percentage: parseFloat(fatPercentage.toFixed(2)),
        },
        alcohol: {
          quantity: parseFloat(sumOfAlcohol.toFixed(2)),
          percentage: parseFloat(alcoholPercentage.toFixed(2)),
        },
        fibers: {
          quantity: parseFloat(sumOfFibers.toFixed(2)),
          percentage: parseFloat(fiberPercentage.toFixed(2)),
        },
        sugars: {
          quantity: parseFloat(sumOfSugars.toFixed(2)),
          percentage: parseFloat(sugarPercentage.toFixed(2)),
        },
        energy: {
          quantity: parseFloat(sumOfEnergy.toFixed(2)),
        },
      }
    }
    const buildMealsData = async () => {
      const mealIdsToDelete = [];
      let mealPlanDaysList = await getMealPlanDaysList();
      mealPlanDaysList = mealPlanDaysList.filter(elem => !!elem);
      const transformedData = mealPlanDaysList.map(mealPlanDay => {
        const transformedMealDay = mealPlanDay.meals.map(meal => {
          // Extract meal type information
          const mealType = meal.meal_type;
          const mealTypeId = mealType.id;
          const mealName = meal.name ?? mealType.name;
          mealIdsToDelete.push(meal.id)
          // Extract meal items information
          const mealItems = meal.meal_items.map(meal_item => {
            return meal_item.ingredient_options.map(ingredient_option => {
              const ingredientElem = ingredient_option.ingredient;
              const ingredientQuantity = ingredient_option.quantity;
              const ingredientNutritionFacts = ingredientElem.nutrition_facts;
              return {
                ingredient: {
                  id: ingredientElem.id,
                  name_it: ingredientElem.name,
                  name_en: ingredientElem.name,
                  categories_id: ingredientElem.categories_id,
                  energy: computeMacroQuantity(ingredientNutritionFacts.energy.quantity, ingredientQuantity),
                  fats: computeMacroQuantity(ingredientNutritionFacts.fats.quantity, ingredientQuantity),
                  carbohydrates: computeMacroQuantity(ingredientNutritionFacts.carbohydrates.quantity, ingredientQuantity),
                  fibers: computeMacroQuantity(ingredientNutritionFacts.fibers.quantity, ingredientQuantity),
                  sugars: computeMacroQuantity(ingredientNutritionFacts.sugars.quantity, ingredientQuantity),
                  alcohol: computeMacroQuantity(ingredientNutritionFacts.alcohol.quantity, ingredientQuantity),
                  proteins: computeMacroQuantity(ingredientNutritionFacts.proteins.quantity, ingredientQuantity),
                  created_at: ingredientElem.created_at,
                  updated_at: ingredientElem.updated_at,
                  brand: ingredientElem.brand,
                  specific_info: ingredientElem.specific_info,
                  description_it: ingredientElem.description_it,
                  description_en: ingredientElem.description_en,
                  simple_name_it: ingredientElem.simple_name_it,
                  simple_name_en: ingredientElem.simple_name_en,
                  product: ingredientElem.product,
                },
                quantity: ingredientQuantity,
                selected: ingredient_option.selected,
              };
            });
          })

          const macronutrients = computeMealMacros(mealItems)
          // Construct the transformed object
          return {
            meal_types_id: mealTypeId,
            name_it: mealName,
            meal_items: mealItems,
            notes: meal.notes,
            selected: meal.selected,
            macronutrients: macronutrients,
            days_id: meal.days_id,
          };
        });
        return transformedMealDay;
      });
      setMealIdsToDelete(mealIdsToDelete);
      setMealsData(transformedData);
    }
    if (mealPlanToEdit) {
      //TODO: now mealPlanToEdit.advice is an Array of advices, take the first one by now
      if (mealPlanToEdit.advice) {
        setAdvice(mealPlanToEdit.advice[0])
      }
      const mealPlan = mealPlanToEdit.meal_plan
      const copiedMealPlan = { ...mealPlan };
      if (copiedMealPlan.visit) {
        const visitId = copiedMealPlan.visit.id
        copiedMealPlan.visit_id = visitId
        delete copiedMealPlan.visit
      }
      copiedMealPlan.number_of_days = mealPlanToEdit.days.length
      setMealPlanData(copiedMealPlan)
      buildMealsData();
    }
  }, [mealPlanToEdit, mealPlanId, getMealPlanDays]);

  const handleNext = (event) => {
    if (!isFormValid()) {
      setAnchorMissingFormField(event.currentTarget);
      return;
    }
    const numberOfDays = mealPlanData.number_of_days;
    const mealsDataCopy = [...mealsData]
    const mealsDataCopyLength = mealsDataCopy.length
    if (mealsDataCopyLength > numberOfDays) {
      const mealsDataToUpdate = mealsDataCopy.slice(0, numberOfDays)
      setMealsData(mealsDataToUpdate)
    }
    else if (mealsDataCopyLength < numberOfDays) {
      mealsDataCopy.push(...Array.from({ length: numberOfDays - mealsDataCopyLength }, () => []))
      setMealsData(mealsDataCopy)
    }
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };
  const isFormValid = () => {
    // Here we are in the client information page
    return (mealPlanData.hasOwnProperty("name") && mealPlanData.name.trim() !== "")
      && (mealPlanData.hasOwnProperty("number_of_days") && mealPlanData.number_of_days)
  };

  const callGenerateRecipe = async (meal) => {
    try {
      setIsGenerateRecipeLoading(true);
      const res = await axios.post(
        `${pythonApiUrl}/recipes`,
        { meal: meal },
        {
          headers: {
            Authorization: `Bearer ${accessToken}`
          }
        }
      );

      if (res.status === 200) {
        console.info("Recipe created from meal id:", meal.id)
      }
    } catch (error) {
      setIsGenerateRecipeLoading(false);
      console.error("An error occurred while generating a recipe from meal:", error.message);
    } finally {
      setIsGenerateRecipeLoading(false);
    }

  }
  const handleSaveEdit = async () => {
    try {
      const mealPlansResponse = await putMealPlans({
        data: {
          name: mealPlanData.name,
          start_date: mealPlanData.start_date,
          end_date: mealPlanData.end_date,
          goal: mealPlanData.goal,
          visit_id: mealPlanData.visit_id,
          number_of_days: mealPlanData.number_of_days,
        },
        id: mealPlanData.id
      });


      if (advice) {
        const remindersResponse = await postReminders({ description: advice.description });
        let remindersId;
        if (remindersResponse.error && remindersResponse.error.status === 419) {
          remindersId = remindersResponse.error.data.data.reminder_id;
        }
        else {
          remindersId = remindersResponse.data.id;
        }
        await putReminders({ mealPlanId: mealPlanData.id, remindersId });
      }
      const mealsByDayUpdated = mealsData.map((mealDayData, index) => {
        mealDayData.map(meal => {
          meal.days_id = mealPlansResponse.data.days[index].id
          return meal
        })
        return mealDayData
      })
      const flatMeals = mealsByDayUpdated.flat()
      for (const innerArray of flatMeals) {
        innerArray.meal_items.forEach(itemArray => {
          itemArray.forEach(item => {
            item.ingredients_id = item.ingredients_id || item.ingredient.id;
            delete item.ingredient;
          });
        });
        const transformedObject = {
          ...innerArray,
          meal_items: innerArray.meal_items.map(itemsArray => ({
            ingredient_options: itemsArray.map(item => ({
              ...item
            }))
          }))
        };
        delete transformedObject.macronutrients
        const res = await postMeal(transformedObject);
      }

      const deleteMealBatchResponse = await deleteBatchMeal(mealIdsToDelete);
      console.log("Delete response is:", deleteMealBatchResponse);

      setActiveStep(0);
      navigate(`/pazienti/details/${clientId}`);
      window.location.reload();
    } catch (error) {
      console.log("Error while creating meal plans", error);
    }
  }
  const handleReset = async () => {
    try {
      const clientId = mealPlanData.client_id;
      const mealPlansResponse = await postMealPlans(mealPlanData);

      const mealPlanId = mealPlansResponse.data.id;
      const days_id_list = mealPlansResponse.data.days_id_list;

      if (advice) {
        const remindersResponse = await postReminders(advice);
        const remindersId = remindersResponse.data.id;
        await putReminders({ mealPlanId, remindersId });
      }
      const mealsByDayCopy = mealsData.map((day) =>
        day.map((meal) => ({ ...meal, days_id: meal.days_id }))
      );
      // Update the days_id within each object
      const mealsByDayUpdated = mealsByDayCopy.map((mealDayData, index) => {
        mealDayData.map(meal => {
          meal.days_id = days_id_list[index]
          return meal
        })
        return mealDayData
      })
      const flatMeals = mealsByDayUpdated.flat()
      for (const innerArray of flatMeals) {
        innerArray.meal_items.forEach(itemArray => {
          itemArray.forEach(item => {
            item.ingredients_id = item.ingredients_id || item.ingredient.id;
            item.ingredients_name = item.ingredients_name || item.ingredient.name_it;
            delete item.ingredient;
          });
        });
        const transformedObject = {
          ...innerArray,
          meal_items: innerArray.meal_items.map(itemsArray => ({
            ingredient_options: itemsArray.map(item => ({
              ...item
            }))
          }))
        };
        delete transformedObject.macronutrients
        const generateRecipe = transformedObject.generate_recipe;
        delete transformedObject.generate_recipe
        const res = await postMeal(transformedObject);
        if (generateRecipe) {
          const mealId = res.data.id;
          transformedObject.id = mealId;
          transformedObject.client_id = clientId;
          await callGenerateRecipe(transformedObject);
        }
      }

      setActiveStep(0);
      navigate(`/pazienti/details/${clientId}`);
      window.location.reload();
    } catch (error) {
      console.log("Error while creating meal plans", error);
    }
    };

  const handleChangeName = (event) => {
    setMealPlanData({
      ...mealPlanData,
      name: event.target.value,
    });
  };

  const handleChangeVisita = (event) => {
    setMealPlanData({ ...mealPlanData, visit_id: event.target.value });
  };

  const handleChangeGoal = (event) => {
    setMealPlanData({ ...mealPlanData, goal: event.target.value });
  };

  const handleChangeNumberOfDays = (event) => {
    const numberOfDays = Number(event.target.value)
    if (!!!numberOfDays) {
      setMealPlanData({
        ...mealPlanData,
        number_of_days: "",
      });
      return;
    }
    setMealPlanData({
      ...mealPlanData,
      number_of_days: numberOfDays,
    });
  };

  const handleChangeAdvices = (event) => {
    setAdvice({ description: event.target.value });
  };

  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);

  const handleOpenConfirmDialog = () => {
    setOpenConfirmDialog(true);
  };

  const handleCloseConfirmDialog = () => {
    setOpenConfirmDialog(false);
  };

  const handleConfirmDialog = () => {
    setOpenConfirmDialog(false);
    navigate(`/pazienti/details/${clientId}`)
  };

  const handleClosePopoverMissingFormField = () => {
    setAnchorMissingFormField(null);
  };

  const stepContent = [
    <Step1Content
      mealPlanData={mealPlanData}
      advice={advice}
      id={clientId}
      handleChangeNumberOfDays={handleChangeNumberOfDays}
      handleChangeName={handleChangeName}
      handleChangeVisita={handleChangeVisita}
      handleChangeGoal={handleChangeGoal}
      handleChangeAdvices={handleChangeAdvices}
      isEditing={!!mealPlanId}
    />,
    <Step2Content mealPlanData={mealPlanData} mealsData={mealsData} setMealsData={setMealsData} isEditing={!!mealPlanId} />,
  ];

  if (
    isPostMealPlansLoading ||
    isPostRemindersLoading ||
    isPutRemindersLoading ||
    isPostMealLoading ||
    isMealPlanToEditLoading ||
    isGenerateRecipeLoading ||
    isDeleteBatchMealLoading
  ) {
    return <div class="loader-container">
      <div class="loader"></div>
    </div>
      ;
  }

  if (
    isPostMealPlansError ||
    isPutMealPlansError ||
    isPostMealError ||
    isDeleteBatchMealError
  ) {
    return <p>Error...</p>;
  }

  return (
    <Box m="1.5rem 2.5rem">
      <Box sx={{ display: "flex", justifyContent: "space-between" }}>
        <Box>
          <Button
            sx={{
              color: "#464868",
              textDecoration: "underline",
              fontSize: "14px",
              fontWeight: "500",
            }}
            onClick={
              handleOpenConfirmDialog
            }
          >
            {"Annulla"}
          </Button>
        </Box>
        <Stepper
          alternativeLabel
          activeStep={activeStep}
          connector={<QontoConnector />}
        >
          {steps.map((label) => (
            <Step key={label}>
              <StepLabel sx={{ml: "18px"}} StepIconComponent={QontoStepIcon}>{label}</StepLabel>
            </Step>
          ))}
        </Stepper>
        <Box>
          {activeStep > 0 ?
            <div className="gradient-border-wrapper" >
              <Button

                  onClick={handleBack}
                  sx={{
                    color: "linear-gradient(to top right, #B0D99C, #68BE6A)",
                    fontWeight: 600, textTransform: 'none'
                  }}
                  className="gradient-button"
                >

                  {"Indietro"}
                </Button>
              </div>
              : null
            }
            <Button
              sx={{
                background: 'linear-gradient(to top right, #B0D99C, #68BE6A)',
                textTransform: 'none',
                fontWeight: 600,
                ml: "16px",
                borderRadius: "8px",
                padding: "8px 16px",
                color: "white",
                boxShadow: 'none',
                '&:hover, &:active, &:focus': {
                  boxShadow: 'none'
                }
              }}
              variant="contained"
            onClick={activeStep === steps.length - 1 ? ((!!mealPlanId) ? handleSaveEdit : handleReset) : handleNext}
            >
              {activeStep === steps.length - 1 ? "Salva" : "Avanti"}
            </Button>
            <Popover
              id="popover_id"
              open={Boolean(anchorMissingFormField)}
              anchorEl={anchorMissingFormField}
              onClose={handleClosePopoverMissingFormField}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
            >
              <Typography color="error" sx={{ p: 2 }}>Compila i campi mancanti</Typography>
            </Popover>
          </Box>
          <Dialog open={openConfirmDialog} onClose={handleCloseConfirmDialog}>
            <DialogTitle>Conferma annullamento</DialogTitle>
            <DialogContent>
              <DialogContentText>
                Sei sicuro di voler tornare indietro? Perderai i dati inseriti.
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleCloseConfirmDialog} color="error">
                Annulla
              </Button>
            <Button onClick={handleConfirmDialog} sx={{
              background: 'linear-gradient(to top right, #B0D99C, #68BE6A)',
              textTransform: 'none',
              fontWeight: 600,
              boxShadow: 'none',
              borderRadius: "8px",
              padding: "8px 16px",
              color: "white",
              '&:hover, &:active, &:focus': {
                boxShadow: 'none'
              }
            }}>
              Conferma
            </Button>
          </DialogActions>
        </Dialog>
      </Box>
      {stepContent[activeStep]}
    </Box>
  );
};

export default PianoAlimentare;
